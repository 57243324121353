<template>
  <div
    class="folder-card"
    :class="{ 'reorder-handle': isReorderable, imgonly: imageOnly, bordered }"
    @contextmenu="handleContext"
  >
    <div class="position-relative w-100 h-100">
      <ion-button
        v-if="!isReorderable && isRemovable"
        class="position-absolute remove-selection-btn"
        fill="clear"
        @click="remove"
        ><i class="ti-close"
      /></ion-button>
      <component
        :is="imageOnly || limited ? 'a' : 'router-link'"
        class="a-wrap"
        :to="{ name: 'collection-details', params: { slug: folder.slug } }"
      >
        <div class="wrap" @click="toggleSelection">
          <div class="image position-relative d-flex align-items-center justify-content-center">
            <img loading="lazy" v-folder-card-image :src="resizeUpload(folder.cropped_img_url, '250x250')" />
            <div class="position-absolute upper w-100 d-flex justify-content-between">
              <div class="black-small">
                <i class="ti-folder" />
              </div>
              <div
                v-if="!isSelectable && isOwner && !limited"
                class="black-small clickable"
                @click.prevent="openDropDown"
              >
                <i class="ti-more-alt" />
              </div>
              <ion-checkbox
                v-if="isSelectable && isOwner && !imageOnly"
                class="select mt-1 mr-1"
                mode="ios"
                :checked="isSelected"
                :disabled="true"
              />
            </div>
          </div>

          <div v-if="!imageOnly" class="rest-info">
            <div class="name mt-2">
              {{ folder.name }}
            </div>
          </div>
        </div>
      </component>
    </div>
  </div>
</template>

<script lang="ts" setup>
import FolderCardPopover from './popovers/FolderCardPopover.vue';
import { Collection } from '@/shared/types/static-types';
import { popovers } from '@/shared/native/popovers';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  folder: {
    type: Object,
    default: () => ({}),
  },
  isSelected: {
    type: Boolean,
  },
  isSelectable: {
    type: Boolean,
  },
  isRemovable: {
    type: Boolean,
  },
  isReorderable: {
    type: Boolean,
  },
  limited: {
    type: Boolean,
  },
  imageOnly: {
    type: Boolean,
  },
  bordered: {
    type: Boolean,
  },
});

const folder = computed(() => props.folder as Collection);
const isSelectable = computed(() => props.isSelectable as boolean);
const isSelected = computed(() => props.isSelected as boolean);
const isRemovable = computed(() => props.isRemovable as boolean);
const imageOnly = computed(() => props.imageOnly as boolean);

const isReorderable = computed(() => props.isReorderable as boolean);
const handleContext = (e: any) => {
  if (props.isReorderable) return e.preventDefault();
};

const remove = (e: any) => {
  e.preventDefault();

  const data = {
    detail: {
      checked: !isSelected.value,
    },
  } as CustomEvent;

  select(data);
};

const emits = defineEmits(['select']);

const openDropDown = (ev: CustomEvent) => {
  popovers.open(ev, FolderCardPopover, { folder: folder.value });
};

const toggleSelection = (e: any) => {
  if (!isSelectable.value || isRemovable.value) {
    return;
  }

  e.preventDefault();

  const data = {
    detail: {
      checked: !isSelected.value,
    },
  } as CustomEvent;
  select(data);
};

const select = (e: CustomEvent) => {
  emits('select', e.detail.checked);
};

const user = computed(() => {
  const { user } = authStore();
  return user.value;
});

const isOwner = computed(() => {
  try {
    return folder.value.user === user.value.id;
  } catch (error) {
    return false;
  }
});
</script>

<style lang="sass" scoped>
.remove-selection-btn
  z-index: 22
  background: rgba(0,0,0,0.5) !important
  pointer-events: all
  --width: 25px
  --height: 25px
  --padding-start: 8px !important
  --padding-end: 8px !important
  border-radius: 20px
  color: #FFF !important
  min-width: none
  right: -10px
  top: -10px
  overflow: hidden
.disabled
  pointer-events: none
.folder-card
  padding: 6px
  height: 160px
  box-shadow: 1px 1px 5px 0px rgba(33,65,99,0.19)
  border-radius: 12px
  background: #FFF
  &.bordered
    border: 1px solid #CCC
  &.imgonly
    width: 60px
    height: 60px
    min-height: unset
    min-width: 60px
    padding: 0
    border-radius: 6px
    .black-small
      display: none !important
    img, .image, .wrap
      height: 100%
      min-height: 100%
      border-radius: 6px !important

  ::v-deep
    .checkbox-disabled
      opacity: 1 !important
  .image
    border-radius: 6px 6px 0 0
    background: #FF7067
    overflow: hidden
    height: 102px
    img
      width: 100%
      height: 100%
      object-fit: cover
  .name
    overflow: hidden
    text-overflow: ellipsis
    width: 100%
    color: #214163
    font-size: 14px
    font-weight: bold
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    max-width: 100%
  .upper
    top: 0
  .black-small
    background: #0A0928E5
    width: 20px
    height: 20px
    color: #FFF
    display: flex
    justify-content: center
    align-items: center
    font-size: 12px
    border-radius: 6px
.rest-info
  width: 100%
</style>
