<template>
  <div>
    <ion-item class="item-container clickable-item-hov">
      <div class="menu-item">
        <router-link :to="{ name: 'edit-collection', params: { id: folder.id } }" @click="close">
          <small>Edit Folder</small>
        </router-link>
      </div>
    </ion-item>
  </div>
</template>

<script lang="ts" setup>
import Collection from '@/shared/components/Gallery/Collection.vue';

const props = defineProps({
  instance: {
    type: Object,
  },
  folder: {
    type: Object,
  },
});

const close = () => props.instance?.dismiss();
const folder = computed(() => props.folder as Collection);
</script>

<style scoped lang="sass">
.bottom-button
  background-color: var(--ion-color-danger) !important
  color: white
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
</style>
